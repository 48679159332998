





















































import Vue, { PropOptions } from 'vue';
import type { PaddingOptions } from '@/components/SectionContainer.vue';

export type LogoBar = Blok & {
  title: string;
  logos: BlokFieldAsset[];
  showTitle: boolean;
  showFrameworkLabels: boolean;
  mobileAnimation: boolean;
  desktopAnimation: boolean;
  paddingTop?: PaddingOptions;
  paddingBottom?: PaddingOptions;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<LogoBar>,
    fullWidth: Boolean,
  },
  computed: {
    itemsSpacing(): { [key: string]: string } {
      return {
        '--items-spacing': this.fullWidth
          ? 'calc(var(--spacing-xxl) * 1.4)'
          : 'var(--spacing-xxl)',
      };
    },
  },
});
